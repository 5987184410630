import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 查询 留言板
export const getGuestNoteList = (params) =>
  request({
    url: REST_SERVICE.lyb.getGuestNoteList,
    method: 'get',
    params
  });

//  删除我的留言
export const deleteGuestNote = (params) =>
  request({
    url: REST_SERVICE.lyb.deleteGuestNote,
    method: 'post',
    data: params
  });

// 查询 单条件查询
export const getGuestNoteBySingleQuery = (params) =>
  request({
    url: REST_SERVICE.lyb.getGuestNoteBySingleQuery,
    method: 'get',
    params
  });

// 添加 添加留言内容 saveGuestNote
export const saveGuestNote = (params) =>
  request({
    url: REST_SERVICE.lyb.saveGuestNote,
    method: 'post',
    data: params
  });

//获取登记机构
export const getOrgsByAreaId = (params) =>
  request({
    url: REST_SERVICE.uums.getOrgsByAreaId,
    method: 'get',
    params
  });
