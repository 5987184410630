<template>
  <div class="lyb-form">
    <div class="lyb-top">
      <div class="toptab">
        <img src="@/pages/iebdc/assets/images/message-board.png" />
        <div class="tab-contents">留言信息</div>
      </div>
      <el-form ref="form" :model="detailDate" label-width="80px" class="form-content" disabled>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="留言类型">
              <dict-select v-model="detailDate.category" type="留言类型"></dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="留言时间">
              <el-date-picker v-model="detailDate.ctime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="留言人">
              <el-input type="留言人" v-model="detailDate.lyry"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="留言机构">
              <el-input v-model="detailDate.lyjg" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="留言内容">
              <el-input type="textarea" v-model="detailDate.content" :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="lyb-center">
      <div class="toptab">
        <img src="@/pages/iebdc/assets/images/message-board.png" />
        <div class="tab-contents">留言回复</div>
      </div>
      <el-form ref="form" :model="replyNote" label-width="80px" class="form-content" disabled>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="回复机构">
              <el-input v-model="replyNote.lyjg"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="回复时间">
              <el-date-picker v-model="replyNote.ctime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="回复内容" prop="desc">
              <el-input type="textarea" v-model="replyNote.content" :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="btn-container">
      <el-button type="primary" @click="beback()">返回</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  //接收数据
  props: {
    detailDate: Object,
  },
  computed: {
    ...mapState('area', ['area']),
    ...mapGetters('area', ['getOrgNameByCode']),
  },
  mounted() {
    //机构code转name
    this.detailDate.lyjg = this.getOrgNameByCode(this.detailDate.lyjg);
    if (this.detailDate.replyNote !== null) {
      this.replyNote = this.detailDate.replyNote;
    }
    this.replyNote.lyjg = this.detailDate.lyjg;
  },
  data() {
    return {
      //回复内容
      replyNote: {
        lyjg: '',
        ctime: '',
        content: '',
      },
      //留言信息
      pageMsg: {},
    };
  },
  methods: {
    beback() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.lyb-form {
  .toptab {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 20px 0px;
    .tab-contents {
      font-size: 22px;
      color: #d63a48;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
  .form-content {
    margin: 0 auto;
    text-align: center;
    width: 70%;
  }
  .btn-container {
    text-align: center;
  }
  //设置字体颜色
  /deep/.el-textarea.is-disabled .el-textarea__inner {
    color: #606266;
  }
  /deep/ .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
