<template>
  <div class="content">
    <content-key letter="RECOMMEND" title="留言板"></content-key>
    <div class="lyb-tab">
      <div class="applycation-top" v-if="showMsg && !showDetail">
        <div class="nav">
          <div class="nav-li" :class="{ active: myActive == '0' }" @click="chooseTable('0', null)">全部留言</div>
          <div
            class="nav-li"
            :class="{ active: myActive == '1' }"
            @click="chooseTable('1', null)"
            v-if="hasPermission('IEBDC:LYB:WDLY')"
          >
            我的留言
          </div>
          <div
            class="nav-li"
            :class="{ active: myActive == '2' }"
            @click="chooseTable('2', null)"
            v-if="hasPermission('IEBDC:LYB:WYLY')"
          >
            我要留言
          </div>
        </div>
        <div class="query">
          <div class="query-center form-select">
            <el-select v-model="value" placeholder="请选择" @change="selectQuery()">
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="query-center">
            <div v-if="query1" class="ng-hide">
              <el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div v-if="query2" class="ng-hide">
              <dict-select type="留言类型" v-model="value2"></dict-select>
            </div>
            <div v-if="query3" class="ng-hide">
              <el-input placeholder="关键字" v-model="value3"></el-input>
            </div>
          </div>
          <el-button type="primary" icon="el-icon-search" size="small" @click="searchMsg">搜索</el-button>
        </div>
      </div>
      <div class="lyb-table" v-show="(myActive == '0' || myActive == '1') && !showDetail">
        <table class="table table-shade">
          <tbody>
            <tr v-for="item in lybData" :key="item.content">
              <td class="info">
                <div class="table-name">
                  <span
                    :class="{
                      red: item.category == '1',
                      yellow: item.category == '2',
                      blue: item.category == '3',
                    }"
                  >
                    【
                    <dict-span type="留言类型" :binddata="item.category"></dict-span>】
                  </span>
                  <span class="content" @click="lyDetail(item.id)">{{ item.content }}</span>
                </div>
                <div class="table-info">
                  <span>{{ getDjjgName(item.lyjg, areaData) }}</span>
                  <img src="@/pages/iebdc/assets/images/time.png" />
                  <span>{{ item.ctime | formatDate('yyyy-MM-dd') }}</span>
                  <span class="delete" @click="deletDetail(item.id)" v-show="myActive == '1' && item.status !== 'REPLY'"
                    >删除</span
                  >
                </div>
                <div class="img"></div>
              </td>
              <td class="img">
                <img src="@/pages/iebdc/assets/images/pending.png" v-show="item.status == 'NO_REPLY'" />
                <img src="@/pages/iebdc/assets/images/solved.png" v-show="item.status != 'NO_REPLY'" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="lyb-form ng-hide" v-if="myActive == '2'">
        <div class="toptab">
          <img src="@/pages/iebdc/assets/images/message-board.png" />
          <div class="tab-contents">留言信息</div>
        </div>
        <el-form ref="ruleForm" :model="ruleForm" label-width="80px" class="form-content" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="姓名" prop="lyry">
                <el-input v-model="ruleForm.lyry" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
              <el-form-item label="联系电话" prop="lyrylxfs">
                <el-input v-model="ruleForm.lyrylxfs" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="留言类型" prop="category">
                <dict-select type="留言类型" v-model="ruleForm.category"></dict-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
              <el-form-item label="留言机构" prop="lyjg">
                <el-select-tree
                  v-model="ruleForm.lyjg"
                  check-strictly
                  style="width: 100%"
                  size="small"
                  @change="changeJg"
                  :data="areaData"
                  :props="{ value: 'code', label: 'name', children: 'childrens' }"
                ></el-select-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col>
              <el-form-item label="留言内容" prop="content">
                <el-input type="textarea" v-model="ruleForm.content" :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-form-item>
              <el-button type="danger" @click="onSubmit">提交留言</el-button>
              <el-button @click="resetForm()">重置信息</el-button>
              <el-button type="primary" @click="back">返回</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <msg-detail
        :detailDate="detailDate"
        v-if="showDetail && (myActive == '0' || myActive == '1')"
        @close="close"
      ></msg-detail>
    </div>
  </div>
</template>

<script>
import hasPermission from 'iebdc/mixin/hasPermission';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import MsgDetail from './message-detail/index';
import {
  getGuestNoteList,
  deleteGuestNote,
  getGuestNoteBySingleQuery,
  saveGuestNote,
  getOrgsByAreaId,
} from '@/pages/iebdc/api/message';
import { getOrganBsdt } from '@/pages/iebdc/api/uums';
export default {
  mixins: [hasPermission],
  components: { MsgDetail },
  data() {
    return {
      myActive: '0',
      query1: true,
      query2: false,
      query3: false,
      showMsg: true,
      showDetail: false,
      selectList: [
        {
          value: 'date',
          label: '留言时间',
        },
        {
          value: 'category',
          label: '留言类型',
        },
        {
          value: 'content',
          label: '内容',
        },
      ],
      value1: '',
      value: '',
      value2: '',
      value3: '',
      lybData: [],
      ruleForm: {
        username: IEBDC_USER_DATA().name,
        lyry: IEBDC_USER_DATA().name,
        lyrylxfs:
          IEBDC_USER_DATA().userType === 'jg'
            ? IEBDC_USER_DATA().tel.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')
            : IEBDC_USER_DATA().tel.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2'),
        lyjg: '',
        category: '',
        content: '',
      },
      rules: {
        username: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        lyry: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        lyrylxfs: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        content: [{ required: true, message: '请填写留言内容', trigger: 'blur' }],
        category: [{ required: true, message: '请选择留言类型', trigger: 'change' }],
        lyjg: [{ required: true, message: '请选择留言机构', trigger: 'change' }],
      },
      detailDate: [], //留言内容展示
      areaData: [],
    };
  },
  methods: {
    //更改样式
    chooseTable(val) {
      let arr = Object.keys(IEBDC_USER_DATA());
      //console.log(IEBDC_USER_DATA, 'userData---', IEBDC_USER_DATA.length, 'len-', arr, 'arr');
      if (arr.length === 0) {
        this.$message.warning('请先登录系统');
      } else {
        this.myActive = val;
        if (val === '0' || val === '1') {
          this.getMessageList();
        } else if (val === '2') {
          this.showMsg = false;
        }
      }
    },
    //留言细节
    async lyDetail(id) {
      let param = {
        id: id,
        timestamplp: new Date().getTime(),
      };
      const res = await getGuestNoteList(param);
      if (res.success) {
        res.data.page.forEach((item) => {
          item.lyry = item.lyry ? this.$decryptByDES(item.lyry) : '';
        });
        this.detailDate = res.data.page[0];
        this.showDetail = true;
      }
    },
    //删除留言
    deletDetail(id) {
      deleteGuestNote({ id: id })
        .then((res) => {
          if (res.success) {
            this.getMessageList();
          }
        })
        .catch(() => {});
    },
    //返回
    back() {
      this.showMsg = true;
      this.chooseTable('1');
      this.$refs.ruleForm.resetFields();
    },
    //重置信息
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //提交留言
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = { ...this.ruleForm };
          params.lyrylxfs = IEBDC_USER_DATA().tel;
          saveGuestNote(params).then((res) => {
            if (res.success) {
              this.$message.success('提交成功');
              this.back();
            }
          });
        } else {
          return false;
        }
      });
    },
    close() {
      this.showDetail = false;
    },
    selectQuery() {
      switch (this.value) {
        case 'date':
          this.query1 = true;
          this.query2 = false;
          this.query3 = false;
          break;
        case 'category':
          this.query1 = false;
          this.query2 = true;
          this.query3 = false;
          break;
        case 'content':
          this.query1 = false;
          this.query2 = false;
          this.query3 = true;
          break;
        default:
          '';
      }
    },
    changeJg(value, node) {
      if (node.childrens.length != 0) {
        setTimeout(() => {
          this.ruleForm.lyjg = '';
          this.$message.warning('请选择登记机构');
        }, 0);
      }
    },
    //根据条件去查找内容
    searchMsg() {
      let param;
      if (this.query1) {
        param = {
          timestamplp: new Date().getTime(),
          date: this.value1,
          nCurrent: 0,
        };
      } else if (this.query2) {
        param = {
          timestamplp: new Date().getTime(),
          category: this.value2,
          nCurrent: 0,
        };
      } else {
        param = {
          timestamplp: new Date().getTime(),
          content: this.value3,
          nCurrent: 0,
        };
      }
      if (this.myActive === '0') {
        param.username = null;
      } else {
        param.username = IEBDC_USER_DATA().realName;
      }
      getGuestNoteBySingleQuery(param).then((res) => {
        if (res.success) {
          this.lybData = res.data.page;
        }
      });
    },
    //获取登记机构---(获取的办事大厅接口)
    getDjjg() {
      // formatOrg(column, row) {
      //   const orgName = (row[column.prop]);
      //   return orgName;
      // },)
      getOrganBsdt().then((res) => {
        if (res && res.success) {
          //这里是直接对获取的后台数组进行赋值操作
          // console.log(res.data, 'res.data1');
          this.areaData = this.getJgTree(res.data);
        }
      });
    },
    // 对登记机构进行过滤
    getMessageList() {
      // console.log(111);
      //这里参数需要相应改变
      let param;
      if (this.myActive === '0') {
        param = {
          visible: true,
          nCurrent: 0,
          timestamplp: new Date().getTime(),
        };
      } else {
        param = {
          visible: false,
          nCurrent: 0,
          username: IEBDC_USER_DATA().realName,
          timestamplp: new Date().getTime(),
        };
      }
      getGuestNoteList(param).then((res) => {
        if (res.success === true) {
          this.lybData = res.data.page;
        }
      });
    },
    //重组登记机构树结构
    getJgTree(item) {
      var pPromise = [];
      var grade = {
        index: 0,
        num: item[0].grade,
      };
      for (var i = 0; i < item.length; i++) {
        if (grade.num > item[i].grade) {
          grade = {
            index: i,
            num: item[i].grade,
          };
        }
      }
      pPromise[0] = item[grade.index];
      this.repeatFor(pPromise);
      return pPromise;
    },
    // ---平铺展开areaTree
    repeatFor(item) {
      for (var i = 0; i < item.length; i++) {
        if (item[i].code.indexOf('-') >= 0 || item[i].status == 0) {
          item.splice(i, 1);
          i--;
        } else {
          if (item[i].childrens && item[i].childrens.length > 0) {
            this.repeatFor(item[i].childrens);
          } else {
            // 去除暂时无数据展示
            item[i].childrens = undefined;
          }
        }
      }
    },
    // 机构名字
    getDjjgName(item, areaData) {
      let value = '';
      for (var i = 0; i < areaData.length; i++) {
        if (areaData[i].code.indexOf('-') >= 0 || areaData[i].status == 0) {
          item.splice(i, 1);
          i--;
          // console.log(1)
        } else {
          // console.log(2);
          //如果有children子集
          if (areaData[i].childrens && areaData[i].childrens.length > 0) {
            let code = areaData[i].code;
            if (!item) {
              // 如果没有值，就直接return
              return;
            } else if (item === code) {
              //如果等于循环的code
              return (value = areaData[i].areaName);
            } else {
              //如果不符合就递归获取机构名称
              this.getDjjgName(item, areaData[i].childrens);
            }
          } else {
            // 没有子集
          }
        }
      }
      // console.log()
    },
    // 动态添加对象 obj--对象， prop属性, val值
    creatObject(obj, prop, val) {
      //如果val没有
    },
  },
  async created() {
    // let obj = await this.$store.getters.orgData;
    // if (obj) {
    //   this.areaData = obj[0].childrens[0].childrens;
    // }
  },
  mounted() {
    this.getMessageList();
    this.getDjjg();
    // console.log(IEBDC_USER_DATA, 'IEBDC_USER_DATA');
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 500px;
}
.applycation-top {
  display: inline-block;
  border-bottom: 1px solid #2ba2f3;
  width: 100%;
}
.lyb-tab {
  line-height: 34px;
  .nav {
    display: inline-block;
    .nav-li {
      display: inline-block;
      font-size: 16px;
      padding: 3px 24px;
      margin-right: 5px;
      background-color: #eeeeee;
      color: #666666;
      cursor: pointer;
    }
    .nav-li.active {
      background: #2ba2f3;
      color: #fff;
    }
  }
  .table-condensed {
    tr {
      line-height: 20px;
    }
  }
}
.query {
  display: inline-block;
  margin: 0px;
  float: right;
  .query-center {
    display: inline-block;
    margin: 0 5px;
  }
}
.lyb-table {
  .table {
    width: 100%;
    table-layout: fixed;
    td.info {
      width: 90%;
      .table-name {
        font-size: 18px;
        padding: 15px 0px 10px 10px;
        .red {
          color: #d63a48;
        }
        .blue {
          color: #2283d2;
        }
        .yellow {
          color: #ff9600;
        }
        .content {
          max-width: 750px;
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          cursor: pointer;
          margin-left: 0px;
          padding: 10px 25px 20px 0;
        }
      }
      .table-info {
        span {
          vertical-align: middle;
        }
        img {
          margin-left: 34px;
          padding-right: 10px;
        }
        .delete {
          margin-left: 37px;
          color: #2283d2;
          cursor: pointer;
        }
        color: #666666;
        font-size: 14px;
        padding: 0px 0px 10px 18px;
      }
    }
    td.img {
      img {
        margin-top: 15px;
      }
    }
  }
  .table-shade td {
    border-bottom: 1px dashed #cccccc;
  }
}
.lyb-form {
  .toptab {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 20px 0px;
    .tab-contents {
      font-size: 22px;
      color: #d63a48;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
  .form-content {
    margin: 0 auto;
    text-align: center;
    width: 70%;
  }
  .form-mySelf {
    margin: 0px 100px 0px 80px;
  }
  .btn-container {
    margin: 30px 15px;
    .btn {
      margin-right: 25px;
      padding: 8px 25px;
    }
    .btn-next {
      background-color: #d63a48;
      color: #fff;
    }
    .btn-rewrite {
      background-color: #fff;
      color: #666666;
    }
  }
}
.page {
  margin: 20px 0px;
}

//设置字体颜色
/deep/.el-input.is-disabled .el-input__inner {
  color: #606266;
}
</style>
